<template>
  <div class="pt-12">
    <v-app-bar dark fixed width="100%" src="../assets/banner-test.jpg">
      <v-app-bar-title>
        {{ i18n(form.data, "title") }}
      </v-app-bar-title>

      <v-spacer></v-spacer>

      <v-menu location="end">
        <template v-slot:activator="{ on, attrs }" location="end">
          <v-btn
            v-if="form.data.language === 'ro'"
            outlined
            v-bind="attrs"
            v-on="on"
            class="mr-2"
          >
            <v-img
              class="rounded-circle mr-4"
              width="24"
              :aspect-ratio="1"
              src="../assets/romanian-flag.svg"
            ></v-img>

            Română
          </v-btn>

          <v-btn v-else class="mr-2" outlined v-bind="attrs" v-on="on">
            <v-img
              class="rounded-circle mr-4"
              width="24"
              :aspect-ratio="1"
              src="../assets/english-flag.svg"
            ></v-img>

            English
          </v-btn>
        </template>

        <v-list>
          <v-list-item @click="changeToEnglish">
            <v-img
              class="rounded-circle"
              width="24"
              :aspect-ratio="1"
              src="../assets/romanian-flag.svg"
            ></v-img>
            <v-list-item-title class="ml-4"> Română </v-list-item-title>
          </v-list-item>

          <v-list-item @click="changeToRomanian">
            <v-img
              class="rounded-circle"
              width="24"
              :aspect-ratio="1"
              src="../assets/english-flag.svg"
            ></v-img>
            <v-list-item-title class="ml-4"> English </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-btn @click="(e) => submitForm(e)" color="#66BB6A">
        {{ i18n(form.data, "sendButtonLabel") }}
      </v-btn>
    </v-app-bar>

    <v-sheet color="#008949">
      <v-container>
        <v-row dense>
          <v-col>
            <v-sheet
              color="#008949"
              class="d-flex flex-column flex-md-row justify-space-between align-center"
              style="width: 100% !important"
            >
              <div
                class="d-flex flex-column flex-md-row justify-space-between align-center"
              >
                <img
                  src="../assets/logo-dlg-intermarketing.svg"
                  contain
                  height="156"
                  class="rounded pt-12"
                />
              </div>

              <div>
                <img
                  src="../assets/logo-agraria.jpg"
                  contain
                  height="96"
                  class="rounded pt-12"
                />
                <h1 class="display mb-2 white--text">
                  {{ i18n(form.data, "eventTitle") }}
                </h1>
              </div>

              <div
                class="d-flex flex-column justify-space-between align-center align-md-end white--text"
                style="height: 100%"
              >
                <h2 class="headline mb-2">
                  {{ i18n(form.data, "eventName") }}
                </h2>
                <h2 class="headline mb-2">
                  {{ i18n(form.data, "eventDate") }}
                </h2>

                <h3 class="title mb-0">www.agraria-dlg.ro</h3>
              </div>
            </v-sheet>
          </v-col>
        </v-row>
      </v-container>

      <v-sheet color="transparent">
        <img src="../assets/decor.svg" style="width: 100vw" />
      </v-sheet>

      <v-sheet
        color="#008949"
        class="d-flex flex-column flex-md-row justify-center align-center"
        style="width: 100% !important"
      >
        <h3 class="title mb-2 mt-4 white--text">
          {{ i18n(form.data, "deadline") }}
        </h3>
      </v-sheet>

      <v-container color="#008949">
        <v-row dense>
          <v-col>
            <TheForm
              :form="form"
              :submitFormProp="submitFormProp"
              :submitAgriplantaFormProp="0"
            />

            <v-container class="mb-0 py-0">
              <v-row class="my-0 py-0">
                <v-col class="py-0"> </v-col>

                <v-col class="py-0 text-right">
                  <v-btn @click="(e) => submitForm(e)" class="mb-12">{{
                    i18n(form.data, "sendButtonLabel")
                  }}</v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-col>
        </v-row>
      </v-container>

      <v-sheet color="transparent">
        <img src="../assets/decor.svg" style="width: 100vw" />
      </v-sheet>

      <v-container>
        <v-row dense class="align-center">
          <v-col md="2">
            <div
              class="d-flex flex-column flex-md-row justify-center align-center"
            >
              <img
                src="../assets/logo-dlg-intermarketing.svg"
                contain
                width="128"
              />
            </div>
          </v-col>

          <v-col>
            <h4 class="white--text">Contact</h4>

            <div
              class="d-flex flex-column flex-md-row justify-space-between align-center"
            >
              <v-list three-line color="transparent">
                <v-list-item class="white--text">
                  <v-list-item-content>
                    <v-list-item-title style="white-space: nowrap">
                      <v-icon class="white--text">mdi-account</v-icon>
                      <span class="pl-3 body-1">Mareș Raluca</span>
                    </v-list-item-title>
                    <v-list-item-subtitle
                      class="white--text"
                      style="white-space: nowrap"
                    >
                      <v-icon class="white--text">mdi-phone</v-icon>
                      <span class="pl-3 body-1">+40734 584 291</span>
                    </v-list-item-subtitle>
                    <v-list-item-subtitle
                      class="white--text"
                      style="white-space: nowrap"
                    >
                      <v-icon class="white--text">mdi-mail</v-icon>
                      <span class="pl-3 body-1">r.mares@dlg.org</span>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <v-list three-line color="transparent">
                <v-list-item class="white--text">
                  <v-list-item-content>
                    <v-list-item-title style="white-space: nowrap">
                      <v-icon class="white--text">mdi-account</v-icon>
                      <span class="pl-3 body-1">Adrian Câmpean</span>
                    </v-list-item-title>
                    <v-list-item-subtitle
                      class="white--text"
                      style="white-space: nowrap"
                    >
                      <v-icon class="white--text">mdi-phone</v-icon>
                      <span class="pl-3 body-1">+40799 742 765</span>
                    </v-list-item-subtitle>
                    <v-list-item-subtitle
                      class="white--text"
                      style="white-space: nowrap"
                    >
                      <v-icon class="white--text">mdi-mail</v-icon>
                      <span class="pl-3 body-1">a.campean@dlg.org</span>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <v-list three-line color="transparent">
                <v-list-item class="white--text">
                  <v-list-item-content>
                    <v-list-item-title style="white-space: nowrap">
                      <v-icon class="white--text">mdi-account</v-icon>
                      <span class="pl-3 body-1">Iosif Roxana</span>
                    </v-list-item-title>
                    <v-list-item-subtitle
                      class="white--text"
                      style="white-space: nowrap"
                    >
                      <v-icon class="white--text">mdi-phone</v-icon>
                      <span class="pl-3 body-1">+40732 945 418</span>
                    </v-list-item-subtitle>
                    <v-list-item-subtitle
                      class="white--text"
                      style="white-space: nowrap"
                    >
                      <v-icon class="white--text">mdi-mail</v-icon>
                      <span class="pl-3 body-1">r.iosif@dlg.org</span>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </div>
          </v-col>

          <v-col align="right">
            <img src="../assets/agraria.png" contain height="200" />
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
  </div>
</template>

<script>
import axios from "axios";
import { required, email, regex } from "vee-validate/dist/rules";

import agrariaForm_f4 from "../json/agraria-2025.json";

import FieldTypes from "../components/fields/FieldTypes.vue";
import { extend, ValidationObserver, setInteractionMode } from "vee-validate";
import TheForm from "@/components/TheForm.vue";

export default {
  components: {
    ValidationObserver,
    FieldTypes,
    TheForm,
  },
  data: () => ({
    form: agrariaForm_f4,
    submitFormProp: 0,
  }),
  created() {
    const url = "https://dlg.dev-factory.ro/php-test/get_user.php";
    axios
      .post(url, { user_url: this.$route.params.slugExpozant })
      .then((response) => {
        this.expozant = response.data;
      });
  },
  metaInfo() {
    return {
      title:
        this.i18n(this.form.data, "eventName") +
        " | " +
        this.i18n(this.form.data, "title"),
    };
  },
  mounted() {
    extend("required", {
      ...required,
      message: "required",
    });

    extend("email", {
      ...email,
      message: "email",
    });

    extend("regex", {
      ...regex,
      message: "phone",
    });

    this.elementsPDF = this.form.data.generatorPDF.elements;
  },
  methods: {
    submitForm(e) {
      // console.log(ValidationObserver)
      this.submitFormProp++;
    },
    changeToRomanian() {
      this.form.data.language = "en";
      this.$store.commit("updateLanguage", "en");
    },
    changeToEnglish() {
      this.form.data.language = "ro";
      this.$store.commit("updateLanguage", "ro");
    },
    i18n(obj, key) {
      if (!obj) return;
      if (obj.i18n) {
        if (obj.i18n[this.form.data.language]) {
          if (obj.i18n[this.form.data.language][key]) {
            return obj.i18n[this.form.data.language][key];
          }
        }
      }
      return obj[key];
    },
  },
};
</script>

<style></style>
