<template>

	<v-sheet
		color="#008949"
		style="min-height: 100vh;"
		class="d-flex justify-center align-center"
	>
		<div v-if="lang === 'ro'" style="color: white; text-align: center;">
			<img src="../assets/agraria.png" contain width="320" class="rounded pt-12" />
			<h5 class="title">Intentia de participare a fost inregistrata.</h5>
			<h3 class="headline">Multumim!</h3>

			<v-container>
		        <v-row>
		            <v-col cols="12" sm="12">
						<v-sheet rounded class="pa-4">
							Pe baza solicitărilor menţionate in Intentia de Participare, DLG va trimite propunerea de amplasare a spaţiului expoziţional , după criterii organizatorice/logistice, în zona în care se află standurile cu adâncimi prestabilite (menţionate în planul care va fi transmis expozantului) şi în limita disponibilului. Propunerile de amplasare pentru Intentiile primite pana la data limita (30.11.2024) vor fi transmise incepand cu data de 02.12.2024  Aceasta intentie nu are valoare contractuala.
						</v-sheet>
					</v-col>
				</v-row>
			</v-container>
		</div>

		<div v-else style="color: white; text-align: center;">
			<img src="../assets/agraria.png" contain width="320" class="rounded pt-12" />
			<h5 class="title">Intent to participate has been registered.</h5>
			<h3 class="headline">Thank you!</h3>

			<v-container>
		        <v-row>
		            <v-col cols="12" sm="12">
						<v-sheet rounded class="pa-4">
							Based on the requests mentioned in the Intent to Participate, DLG will submit the proposal for the location of the exhibition space, according to organisational/logistical criteria, in the area where the stands are located with pre-established depths (mentioned in the plan to be sent to the exhibitor) and within the limits of availability. The location proposals for the Intentions received by the deadline (30.11.2024) will be transmitted starting from 02.12.2024. This intention does not have contractual value.
						</v-sheet>
					</v-col>
				</v-row>
			</v-container>
		</div>
	</v-sheet>

</template>

<script>
	export default {
		data: () => ({
	        lang: null
	    }),
	    mounted () {
	    	this.lang = this.$store.getters.getLanguage
	    }
	}
</script>

<style>
	
</style>