<template>
  <v-responsive align="left" style="background-color: transparent">
    <validation-observer
      ref="observer"
      v-slot="{ invalid }"
      tag="form"
      @submit.prevent="submit()"
    >
      <v-window v-model="step">
        <v-window-item
          :value="index"
          :key="section.id"
          v-for="(section, index) in form.sections"
        >
          <v-container class="mb-0">
            <v-row class="my-0 py-0">
              <v-col
                v-for="field in section.fields"
                :key="field.id"
                cols="12"
                class="my-0 mb-4 py-0"
                :sm="field.width ? field.width.sm : 12"
                :md="field.width ? field.width.md : 12"
              >
                <!-- <h3>{{ field.id }}</h3> -->
                <field-types :form="form" :field="field"></field-types>
              </v-col>
            </v-row>
          </v-container>
        </v-window-item>
      </v-window>

      <v-sheet class="d-flex mb-0 bg-surface-variant">
        <v-sheet class="me-auto">
          <v-btn v-if="step > 0" variant="text" @click="step--">
            {{ form.sections[step - 1].label || "&#x2190;" }}
          </v-btn>
        </v-sheet>

        <v-sheet class="">
          <v-btn
            v-if="step < form.sections.length - 1"
            color="primary"
            variant="flat"
            @click="step++"
          >
            {{ form.sections[step + 1].label || "&#x2192;" }}
          </v-btn>
        </v-sheet>
      </v-sheet>
    </validation-observer>
  </v-responsive>
</template>

<script>
import api from "../mixins/api";
import { required, email, regex } from "vee-validate/dist/rules";
import { extend, ValidationObserver, setInteractionMode } from "vee-validate";
import FieldTypes from "./fields/FieldTypes.vue";

setInteractionMode("eager");

export default {
  name: "TheForm",
  emits: ["submitForm"],
  mixins: [api],
  props: {
    form: {
      type: Object,
      required: true,
    },
    submitFormProp: {
      type: Number,
      required: true,
    },
    submitAgriplantaFormProp: {
      type: Number,
      required: true,
    },
  },
  components: {
    ValidationObserver,
    FieldTypes,
  },
  data: () => ({
    step: 0,
  }),
  created() {},
  mounted() {
    extend("required", {
      ...required,
      message: "required",
    });

    extend("email", {
      ...email,
      message: "email",
    });

    extend("regex", {
      ...regex,
      message: "phone",
    });
  },
  methods: {
    async submit(e) {
      const isValid = await this.$refs.observer.validate();
      if (!isValid) {
        console.log("abort", e);
      }
      console.log("submit", e);
    },
    i18n(obj, key) {
      if (obj.i18n) {
        if (obj.i18n[this.form.data.language]) {
          if (obj.i18n[this.form.data.language][key]) {
            return obj.i18n[this.form.data.language][key];
          }
        }
      }
      return obj[key];
    },
  },
  watch: {
    submitFormProp: async function (newVal, oldVal) {
      const isValid = await this.$refs.observer.validate();
      if (!isValid) {
        const errorField = document.querySelector(".error--text");
        if (errorField) {
          errorField.scrollIntoView({ behavior: "smooth", block: "center" });
        }
      }

      this.$refs.observer.validate().then((res) => {
        if (res) {
          this.saveForm(this.form);
        }
      });
    },
    submitAgriplantaFormProp: async function (newVal, oldVal) {
      const isValid = await this.$refs.observer.validate();
      if (!isValid) {
        const errorField = document.querySelector(".error--text");
        if (errorField) {
          errorField.scrollIntoView({ behavior: "smooth", block: "center" });
        }
      }

      this.$refs.observer.validate().then((res) => {
        if (res) {
          this.saveAgriplantaForm(this.form);
        }
      });
    },
  },
};
</script>
