import axios from 'axios'

const api = {
    data: () => ({
        isDev: false,
        apiPaths: {
            dev: 'http://localhost/dlg-intentie/api/',
            //     // pro: 'https://dlg-intentie.dev-factory.ro/api/'
            pro: 'https://forms.dlg-intermarketing.ro/api/'
        },
        apiPath: null
    }),
    created() {
        this.apiPath = this.getApiPath();
    },
    computed: {
        isLoggedIn: {
            get() {
                return this.$store.getters.getLoggedIn
            },
            set(event) {
                this.$store.commit('setLoggedIn', event)
            }
        },
        isAdmin: {
            get() {
                return this.$store.getters.getIsAdmin
            },
            set(event) {
                this.$store.commit('setIsAdmin', event)
            }
        },
        token: {
            get() {
                return this.$store.getters.getToken
            },
            set(event) {
                this.$store.commit('setToken', event)
            }
        },
        evenimente: {
            get() {
                return this.$store.getters.getEvenimente
            },
            set(event) {
                this.$store.commit('setEvenimente', event)
            }
        },
        expozanti: {
            get() {
                return this.$store.getters.getExpozanti
            },
            set(event) {
                this.$store.commit('setExpozanti', event)
            }
        },
        expozantId: {
            get() {
                return this.$store.getters.getExpozantId
            },
            set(event) {
                this.$store.commit('setExpozantId', event)
            }
        },
        expozantNume: {
            get() {
                return this.$store.getters.getExpozantNume
            },
            set(event) {
                this.$store.commit('setExpozantNume', event)
            }
        },
        expozantTel: {
            get() {
                return this.$store.getters.getExpozantTel
            },
            set(event) {
                this.$store.commit('setExpozantTel', event)
            }
        },
        expozantCui: {
            get() {
                return this.$store.getters.getExpozantCui
            },
            set(event) {
                this.$store.commit('setExpozantCui', event)
            }
        },
        expozantEmail: {
            get() {
                return this.$store.getters.getExpozantEmail
            },
            set(event) {
                this.$store.commit('setExpozantEmail', event)
            }
        },

        expozantPersoanaContact: {
            get() {
                return this.$store.getters.getExpozantPersoanaContact
            },
            set(event) {
                this.$store.commit('setExpozantPersoanaContact', event)
            }
        },
        expozantBanca: {
            get() {
                return this.$store.getters.getExpozantBanca
            },
            set(event) {
                this.$store.commit('setExpozantBanca', event)
            }
        },
        expozantContBancar: {
            get() {
                return this.$store.getters.getExpozantContBancar
            },
            set(event) {
                this.$store.commit('setExpozantContBancar', event)
            }
        },
        expozantLogoUrl: {
            get() {
                return this.$store.getters.getExpozantLogoUrl
            },
            set(event) {
                this.$store.commit('setExpozantLogoUrl', event)
            }
        },
        expozantUserUrl: {
            get() {
                return this.$store.getters.getExpozantUserUrl
            },
            set(event) {
                this.$store.commit('setExpozantUserUrl', event)
            }
        },
        expozantWebsite: {
            get() {
                return this.$store.getters.getExpozantWebsite
            },
            set(event) {
                this.$store.commit('setExpozantWebsite', event)
            }
        },
        expozantiNeaprobati: {
            get() {
                return this.$store.getters.getExpozantiNeaprobati
            },
            set(event) {
                this.$store.commit('setExpozantiNeaprobati', event)
            }
        },
        currentForm: {
            get() {
                return this.$store.getters.getCurrentForm
            },
            set(event) {
                this.$store.commit('setCurrentForm', event)
            }
        },
        expozantAdresa: {
            get() {
                return this.$store.getters.getExpozantAdresa
            },
            set(event) {
                this.$store.commit('setExpozantAdresa', event)
            }
        },
        expozantTel: {
            get() {
                return this.$store.getters.getExpozantTel
            },
            set(event) {
                this.$store.commit('setExpozantTel', event)
            }
        },
        centralizator: {
            get() {
                return this.$store.getters.getCentralizator
            },
            set(event) {
                this.$store.commit('setCentralizator', event)
            }
        },
        selectedExpozant: {
            get() {
                return this.$store.getters.getSelectedExpozant
            },
            set(event) {
                this.$store.commit('setSelectedExpozant', event)
            }
        },
        currentUser: {
            get() {
                return this.$store.getters.getCurrentUser
            },
            set(event) {
                this.$store.commit('setCurrentUser', event)
            }
        },
        centralizatorIntentieAgraria: {
            get() {
                return this.$store.getters.getCentralizatorIntentieAgraria
            },
            set(event) {
                this.$store.commit('setCentralizatorIntentieAgraria', event)
            }
        },
        centralizatorIntentieAgriplanta: {
            get() {
                return this.$store.getters.getCentralizatorIntentieAgriplanta
            },
            set(event) {
                this.$store.commit('setCentralizatorIntentieAgriplanta', event)
            }
        }
        // formularSalvatCuSucces: {
        //     get () {
        //         return this.$store.getters.getFormularSalvatCuSucces
        //     },
        //     set (event) {
        //         this.$store.commit( 'setFormularSalvatCuSucces', event )
        //     }
        // }
    },
    methods: {
        setToken() {
            const token = this.getCookie('dlg-forms-token');
            this.$store.commit('setToken', token);
            this.token = token;
        },
        getApiPath() {
            return this.isDev ? this.apiPaths.dev : this.apiPaths.pro;
        },
        login(email, pass) {
            axios.post(`${this.apiPath}login-user.php`, { data: { email, pass } }).then((response) => {
                if (response.data.status === 1) {
                    this.setCookie('dlg-forms-token', response.data.token, 1);
                    this.isLoggedIn = true;
                    this.isAdmin = response.data.is_admin == 1;
                    this.token = response.data.token;
                    this.getUnapprovedExhibitors(response.data.token);
                    this.$router.push({ name: 'date-agraria' });
                } else {

                }
            }).catch((err) => {
                console.error(err);
                alert(err);
            });
        },
        logout(e) {
            document.cookie = "dlg-forms-token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
            location.reload()
        },
        checkLogin(token) {
            axios.post(`${this.apiPath}validate-token.php`, { data: { token } }).then((response) => {
                if (response.data.status === 1) {
                    this.isLoggedIn = true;
                    this.$store.commit('setIsAdmin', response.data.is_admin == 1);
                    // const isRegister = this.$route.matched.some(({ name }) => name === 'register');
                    const isLogin = this.$route.matched.some(({ name }) => name === 'login');
                    if (isLogin) {
                        // this.$router.push({ name: 'date-agraria' });
                    }
                } else {
                    const isLogin = this.$route.matched.some(({ name }) => name === 'login');
                    if (!isLogin) {
                        // this.$router.push({ name: 'login' });
                    }
                }
            }).catch((err) => {
                console.error(err);
            });
        },
        getExpozant(token) {
            axios.post(`${this.apiPath}get_expozant.php`, { token }).then(response => {
                if (response.data.status === 1) {
                    const expozant = response.data.expozant;
                    this.expozantId = expozant.id;
                    this.expozantNume = expozant.nume;
                    this.expozantTel = expozant.nr_tel;
                    this.expozantCui = expozant.cui;
                    this.expozantEmail = expozant.email;
                    this.expozantAdresa = expozant.adresa;
                    this.expozantPersoanaContact = expozant.persoana_contact;
                    this.expozantBanca = expozant.banca;
                    this.expozantContBancar = expozant.cont_bancar;
                    this.expozantLogoUrl = expozant.logo_url;
                    this.expozantUserUrl = expozant.user_url;
                    this.expozantWebsite = expozant.website;
                } else {
                    this.$router.push({ name: 'login' });
                }
            });
        },
        getCurrentUser(token) {
            axios.post(`${this.apiPath}get_current_user.php`, { token }).then(response => {
                if (response.data.status === 1) {
                    // console.log(response.data.expozant)
                    this.currentUser = response.data.expozant;
                } else {
                    this.$router.push({ name: 'login' });
                }

            });
        },
        setExpozanti(token) {
            axios.post(`${this.apiPath}get_expozanti.php`, { token }).then(response => {
                if (response.data.status === 1) {
                    this.expozanti = response.data.expozanti;
                } else {
                    this.$router.push({ name: 'login' });
                }
            });
        },
        getUnapprovedExhibitors(token) {
            axios.post(`${this.apiPath}get-unapproved-users.php`, { data: { token } }).then((response) => {
                if (response.data.status === 1) {
                    if (response.data.expozanti) {
                        this.expozantiNeaprobati = response.data.expozanti;
                    }
                }
            }).catch((err) => {
                console.error(err);
            });
        },
        aprobaExpozant(cui, token) {
            axios.post(`${this.apiPath}approve-expozant.php`, {
                data: {
                    cui: cui
                }
            }).then(response => {
                this.$store.commit('setNumarCereriExpozanti', response.data.length);
                this.$store.commit('setExpozantiNeaprobati', response.data);
                this.setExpozanti(token);
            });
        },
        respingeExpozant(cui, token) {
            axios.post(`${this.apiPath}delete-expozant.php`, {
                data: {
                    cui: cui
                }
            }).then(response => {
                this.$store.commit('setNumarCereriExpozanti', response.data.length);
                this.$store.commit('setExpozantiNeaprobati', response.data);
                this.deleteExpozantDialog = false
            });
        },
        adaugaExpozant(form, token) {
            const formData = new FormData();

            formData.append('logo', form.logo);
            formData.append('adresa', form.adresa);
            formData.append('banca', form.banca);
            formData.append('cont_bancar', form.cont_bancar);
            formData.append('cui', form.cui);
            formData.append('email', form.email);
            formData.append('nr_tel', form.nr_tel);
            formData.append('nume', form.nume);
            formData.append('persoana_contact', form.persoana_contact);
            formData.append('website', form.website);
            formData.append('parola_1', form.parola_1);
            formData.append('parola_2', form.parola_2);
            formData.append('approved', form.approved ? 1 : 0);

            axios.post(`${this.apiPath}save-expozant.php`, formData).then(response => {
                if (response.data.status == 0) {
                    this.$root.$emit('expozantAdaugat');
                } else if (response.data.status == 1) {
                    this.setExpozanti(token);
                    if (!form.approved) {
                        this.$store.commit('setNumarCereriExpozanti', this.$store.getters.getNumarCereriExpozanti + 1);
                        this.setExpozanti(token);
                    }
                    this.$root.$emit('expozantAdaugat');
                }
            });
        },
        setEvenimente(token) {
            if (!token) return;
            axios.post(`${this.apiPath}get_evenimente.php`, { token }).then(response => {
                if (response.data.status == 1) {
                    this.evenimente = response.data.evenimente
                }
            });
        },
        saveForm(form) {
            axios.post(`${this.apiPath}save-form-agraria.php`, { data: { form } }).then(response => {
                console.log(response.data);
                this.$router.push({ path: '/agraria-multumim' })
            });
        },
        saveAgriplantaForm(form) {
            console.log(form)
            axios.post(`${this.apiPath}save-form-agriplanta.php`, { data: { form } }).then(response => {
                console.log(response.data);
                if (response.data.status === 0) {
                    alert('A aparut o eroare. Incearca sa elimini caracterele precum "tab" si "enter" din campurile completate');
                } else {
                    this.$router.push({ path: '/agriplanta-multumim' })
                }
            });
        },
        getForm(id, token) {
            axios.post(`${this.apiPath}get-formular.php`, { data: { id, token } }).then(response => {
                this.currentForm = JSON.parse(response.data.formular);
            });
        },
        setCookie(cname, cvalue, exdays) {
            const d = new Date();
            d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
            let expires = "expires=" + d.toUTCString();
            document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
        },
        getCookie(cname) {
            let name = cname + "=";
            let decodedCookie = decodeURIComponent(document.cookie);
            let ca = decodedCookie.split(';');
            for (let i = 0; i < ca.length; i++) {
                let c = ca[i];
                while (c.charAt(0) == ' ') {
                    c = c.substring(1);
                }
                if (c.indexOf(name) == 0) {
                    return c.substring(name.length, c.length);
                }
            }
            return "";
        },
        getCentralizator(idForm, idEvent, token) {
            axios.post(`${this.apiPath}get-centralizator.php`, { data: { idForm, idEvent, token } }).then(response => {
                this.centralizator = response.data;
                this.$root.$emit('centralizator');
            });
        },
        getCentralizatorAgraria() {
            axios.post(`${this.apiPath}centralizator-intentie-agraria.php`).then(response => {
                this.centralizatorIntentieAgraria = response.data
            });
        },
        getCentralizatorAgriplanta() {
            axios.post(`${this.apiPath}centralizator-intentie-agriplanta.php`).then(response => {
                this.centralizatorIntentieAgriplanta = response.data
            });
        },
        getExpozantBySlug(slug, token) {
            axios.post(`${this.apiPath}get_expozant_by_slug.php`, { slug, token }).then(response => {
                if (response.data.status === 1) {
                    this.selectedExpozant = response.data.expozant;
                } else {
                    this.$router.push({ name: 'login' });
                }
            });
        },
        updateExpozant(form, token) {
            axios.post(`${this.apiPath}update_expozant.php`, { form, token }).then(response => {
            });
        },
        updateUser(form) {
            axios.post(`${this.apiPath}update_current_user.php`, form, { headers: { 'Content-Type': 'multipart/form-data' } }).then(response => {
                this.$root.$emit('updateUser', response.data);
            });
        },
        setCentralizator(idExpozant, idFormular, idEveniment, json, token) {
            axios.post(`${this.apiPath}set_centralizator.php`, { idExpozant, idFormular, idEveniment, json, token }).then(response => {
                console.log(response.data)
            });
        },
        uploadContract(contract, token) {
            axios.post(`${this.apiPath}upload_contract.php`, contract).then(response => {
                console.log(response)
            });
        }
    }
}

export default api