var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(
      _vm.field.type === 'text' &&
      (_vm.field.conditions && _vm.field.conditions.mode === 'show'
        ? _vm.conditions(_vm.field, _vm.field.conditions)
        : true)
    )?_c('validation-provider',{attrs:{"name":_vm.field.label,"rules":{
      required: _vm.field.required,
    }},on:{"validate":function($event){return _vm.validate()}},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"label":_vm.i18n(_vm.field, 'label'),"filled":"","validate-on":"blur","dense":"","required":_vm.field.required,"error-messages":_vm.i18nErrors(errors)},model:{value:(_vm.field.value),callback:function ($$v) {_vm.$set(_vm.field, "value", $$v)},expression:"field.value"}})]}}],null,false,959337365)}):_vm._e(),(
      _vm.field.type === 'textarea' &&
      (_vm.field.conditions && _vm.field.conditions.mode === 'show'
        ? _vm.conditions(_vm.field, _vm.field.conditions)
        : true)
    )?_c('validation-provider',{attrs:{"name":_vm.field.label,"rules":{
      required: _vm.field.required,
    }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-textarea',{attrs:{"label":_vm.i18n(_vm.field, 'label'),"filled":"","validate-on":"blur","counter":_vm.field.maxValue,"required":_vm.field.required,"dense":"","error-messages":_vm.i18nErrors(errors)},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.preventDefault();}},model:{value:(_vm.field.value),callback:function ($$v) {_vm.$set(_vm.field, "value", $$v)},expression:"field.value"}})]}}],null,false,1796665512)}):_vm._e(),(
      _vm.field.type === 'mail' &&
      (_vm.field.conditions && _vm.field.conditions.mode === 'show'
        ? _vm.conditions(_vm.field, _vm.field.conditions)
        : true)
    )?_c('validation-provider',{attrs:{"name":_vm.i18n(_vm.field, 'label'),"rules":{
      required: _vm.field.required,
      email: 'email',
    }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"label":_vm.i18n(_vm.field, 'label'),"filled":"","dense":"","required":"","error-messages":_vm.i18nErrors(errors)},model:{value:(_vm.field.value),callback:function ($$v) {_vm.$set(_vm.field, "value", $$v)},expression:"field.value"}})]}}],null,false,800043621)}):_vm._e(),(
      _vm.field.type === 'mail-confirm' &&
      (_vm.field.conditions && _vm.field.conditions.mode === 'show'
        ? _vm.conditions(_vm.field, _vm.field.conditions)
        : true)
    )?_c('validation-provider',{attrs:{"name":_vm.i18n(_vm.field, 'label'),"rules":{
      required: _vm.field.required,
      email: 'email',
    }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"label":_vm.i18n(_vm.field, 'label'),"filled":"","dense":"","required":"","error-messages":_vm.i18nErrors(errors)},on:{"blur":_vm.onBlurFunction},model:{value:(_vm.field.value),callback:function ($$v) {_vm.$set(_vm.field, "value", $$v)},expression:"field.value"}})]}}],null,false,1347672685)}):_vm._e(),(
      _vm.field.type === 'phone' &&
      (_vm.field.conditions && _vm.field.conditions.mode === 'show'
        ? _vm.conditions(_vm.field, _vm.field.conditions)
        : true)
    )?_c('validation-provider',{attrs:{"name":_vm.field.label,"rules":{
      required: _vm.field.required,
      phone: 'phone',
    }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"label":_vm.i18n(_vm.field, 'label'),"filled":"","dense":"","required":"","error-messages":_vm.i18nErrors(errors)},model:{value:(_vm.field.value),callback:function ($$v) {_vm.$set(_vm.field, "value", $$v)},expression:"field.value"}})]}}],null,false,800043621)}):_vm._e(),(
      _vm.field.type === 'radio' &&
      (_vm.field.conditions && _vm.field.conditions.mode === 'show'
        ? _vm.conditions(_vm.field, _vm.field.conditions)
        : true)
    )?_c('validation-provider',{attrs:{"name":_vm.i18n(_vm.field, 'label'),"rules":{
      required: _vm.field.required,
    }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-radio-group',{attrs:{"error-messages":_vm.i18nErrors(errors),"required":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"subtitle-1 font-weight-black"},[_vm._v(" "+_vm._s(_vm.i18n(_vm.field, "label"))+" ")])]},proxy:true}],null,true),model:{value:(_vm.field.value),callback:function ($$v) {_vm.$set(_vm.field, "value", $$v)},expression:"field.value"}},_vm._l((_vm.field.items),function(item){return _c('v-radio',{key:item.value,attrs:{"label":_vm.i18n(item, 'label'),"value":item.value},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_c('div',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(_vm.i18n(item, "label")))]),_c('div',{staticClass:"font-weight-regular caption"},[_vm._v(" "+_vm._s(_vm.i18n(item, "hint"))+" ")])])]},proxy:true}],null,true)})}),1)]}}],null,false,4157507339)}):_vm._e(),(
      _vm.field.type === 'select' &&
      (_vm.field.conditions && _vm.field.conditions.mode === 'show'
        ? _vm.conditions(_vm.field, _vm.field.conditions)
        : true)
    )?_c('validation-provider',{attrs:{"name":_vm.i18n(_vm.field, 'label'),"rules":{
      required: _vm.field.required,
    }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-select',{attrs:{"label":_vm.i18n(_vm.field, 'label'),"items":_vm.field.items,"item-text":(item) => _vm.i18n(item, 'label'),"item-value":"value","hint":_vm.i18n(_vm.field, 'hint'),"persistent-hint":"","filled":"","dense":"","error-messages":errors,"required":""},on:{"change":_vm.checkIfSpecialTypeSelected},model:{value:(_vm.field.value),callback:function ($$v) {_vm.$set(_vm.field, "value", $$v)},expression:"field.value"}})]}}],null,false,2895115773)}):_vm._e(),(
      _vm.field.type === 'checkbox' &&
      (_vm.field.conditions && _vm.field.conditions.mode === 'show'
        ? _vm.conditions(_vm.field, _vm.field.conditions)
        : true)
    )?_c('validation-provider',{attrs:{"name":_vm.field.label,"rules":{
      required: _vm.field.required,
    }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('div',{staticClass:"subtitle-1 font-weight-black"},[_vm._v(_vm._s(_vm.i18n(_vm.field, "label")))]),_c('div',{staticClass:"mb-6"},_vm._l((_vm.field.items),function(item){return _c('v-checkbox',{key:item.value,attrs:{"label":_vm.i18n(item, 'label'),"value":item.value,"messages":item.hint,"id":item.value,"disabled":_vm.field.conditions && _vm.field.conditions.mode === 'disable'
            ? _vm.conditions(_vm.field, _vm.field.conditions)
            : false,"hide-details":!!!item.hint,"multiple":"","error-messages":_vm.i18nErrors(errors)},model:{value:(_vm.field.value),callback:function ($$v) {_vm.$set(_vm.field, "value", $$v)},expression:"field.value"}})}),1)]}}],null,false,3423097319)}):_vm._e(),(
      _vm.field.type === 'number' &&
      (_vm.field.conditions && _vm.field.conditions.mode === 'show'
        ? _vm.conditions(_vm.field, _vm.field.conditions)
        : true)
    )?_c('validation-provider',{attrs:{"name":_vm.field.label,"rules":{
      required: _vm.field.required,
    }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"label":_vm.i18n(_vm.field, 'label'),"filled":"","dense":"","required":"","hint":_vm.field.hint,"type":"number","min":_vm.field.min,"error-messages":errors},model:{value:(_vm.field.value),callback:function ($$v) {_vm.$set(_vm.field, "value", $$v)},expression:"field.value"}})]}}],null,false,1378961580)}):_vm._e(),(
      _vm.field.type === 'paragraph' &&
      (_vm.field.conditions && _vm.field.conditions.mode === 'show'
        ? _vm.conditions(_vm.field, _vm.field.conditions)
        : true)
    )?_c('div',[_c('p',{staticClass:"text-body-2"},[_vm._v(_vm._s(_vm.i18n(_vm.field, "value")))])]):_vm._e(),(
      _vm.field.type === 'info' &&
      (_vm.field.conditions && _vm.field.conditions.mode === 'show'
        ? _vm.conditions(_vm.field, _vm.field.conditions)
        : true)
    )?_c('div',[_c('p',{staticClass:"text-h6 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.i18n(_vm.field, "value"))+" ")])]):_vm._e(),(
      _vm.field.type === 'info-phone' &&
      (_vm.field.conditions && _vm.field.conditions.mode === 'show'
        ? _vm.conditions(_vm.field, _vm.field.conditions)
        : true)
    )?_c('div',[_c('p',{staticClass:"text-h6 font-weight-bold"},[_c('a',{attrs:{"href":"tel:+40732945418"}},[_vm._v(" "+_vm._s(_vm.i18n(_vm.field, "value"))+" ")])])]):_vm._e(),(
      _vm.field.type === 'info-email' &&
      (_vm.field.conditions && _vm.field.conditions.mode === 'show'
        ? _vm.conditions(_vm.field, _vm.field.conditions)
        : true)
    )?_c('div',[_c('p',{staticClass:"text-h6 font-weight-bold"},[_c('a',{attrs:{"href":"mailto:r.iosif@dlg.org"}},[_vm._v(" "+_vm._s(_vm.i18n(_vm.field, "value"))+" ")])])]):_vm._e(),(
      _vm.field.type === 'price' &&
      (_vm.field.conditions && _vm.field.conditions.mode === 'show'
        ? _vm.conditions(_vm.field, _vm.field.conditions)
        : true)
    )?_c('div',[_c('v-card',{class:`d-flex justify-space-between mb-6 pa-2 py-4 rounded px-4 ${
        _vm.field.bold ? 'font-weight-black' : ''
      }`,attrs:{"color":_vm.$vuetify.theme.dark ? 'green darken-3' : 'green lighten-4',"flat":"","tile":""}},[_c('div',[_vm._v(" "+_vm._s(_vm.i18n(_vm.field, "label"))+" ")]),_c('div',[(_vm.field.calc)?_c('span',[_vm._v(" "+_vm._s(_vm.calc(_vm.field, _vm.field.calc).toFixed(_vm.field.decimal))+" "+_vm._s(_vm.i18n(_vm.field, "suffix"))+" ")]):(_vm.field.inheritValue)?_c('span',[_vm._v(" "+_vm._s(_vm.inheritValue(_vm.field).toFixed(_vm.field.decimal))+" "+_vm._s(_vm.i18n(_vm.field, "suffix"))+" ")]):(_vm.field.variableValue)?_c('span',[_vm._v(" "+_vm._s(_vm.variableValue(_vm.field).toFixed(_vm.field.decimal))+" "+_vm._s(_vm.i18n(_vm.field, "suffix"))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.field.value.toFixed(_vm.field.decimal))+_vm._s(_vm.i18n(_vm.field, "suffix"))+" ")])])])],1):_vm._e(),(
      _vm.field.type === 'tablevalue' &&
      (_vm.field.conditions && _vm.field.conditions.mode === 'show'
        ? _vm.conditions(_vm.field, _vm.field.conditions)
        : true)
    )?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.field.display != 'none'),expression:"field.display != 'none'"}]},[_vm._v(" "+_vm._s(_vm.tableCalc(_vm.field))+" ")]):_vm._e(),(
      _vm.field.type === 'table' &&
      (_vm.field.conditions && _vm.field.conditions.mode === 'show'
        ? _vm.conditions(_vm.field, _vm.field.conditions)
        : true)
    )?_c('div',[_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',_vm._l((_vm.field.head),function(h,index){return _c('th',{key:index,class:`${h.align === 'right' ? 'text-right' : ''}`},[(h.calc)?_c('span',[_vm._v(" "+_vm._s(_vm.calc(h, h.calc).toFixed(h.decimal))+" "+_vm._s(_vm.i18n(h, "suffix"))+" ")]):(h.inheritValue)?_c('span',[_vm._v(" "+_vm._s(_vm.inheritValue(h).toFixed(h.decimal))+" "+_vm._s(_vm.i18n(h, "suffix"))+" ")]):(h.variableValue)?_c('span',[_vm._v(" "+_vm._s(_vm.variableValue(h).toFixed(h.decimal))+" "+_vm._s(_vm.i18n(h, "suffix"))+" ")]):_c('span',[_vm._v(" "+_vm._s(h.type === "string" ? _vm.i18n(h, "value") : h.value.toFixed(h.decimal))+_vm._s(_vm.i18n(h, "suffix"))+" ")])])}),0)]),_c('tbody',_vm._l((_vm.field.rows),function(row,index){return _c('tr',{key:index},_vm._l((row),function(cell,index){return _c('td',{key:index,class:`${cell.align === 'right' ? 'text-right' : ''} ${
                cell.bold === true ? 'font-weight-bold' : ''
              }`},[(cell.calcColumn)?_c('span',[_vm._v(" "+_vm._s(_vm.calcColumn(_vm.field, cell.calcColumn).toFixed(cell.decimal))+" "+_vm._s(_vm.i18n(cell, "suffix"))+" ")]):(cell.calc)?_c('span',[_vm._v(" "+_vm._s(_vm.calc(cell, cell.calc).toFixed(cell.decimal))+" "+_vm._s(_vm.i18n(cell, "suffix"))+" ")]):(cell.inheritValue)?_c('span',[_vm._v(" "+_vm._s(_vm.inheritValue(cell).toFixed(cell.decimal))+" "+_vm._s(_vm.i18n(cell, "suffix"))+" ")]):(cell.variableValue)?_c('span',[_vm._v(" "+_vm._s(_vm.variableValue(cell).toFixed(cell.decimal))+" "+_vm._s(_vm.i18n(cell, "suffix"))+" ")]):_c('span',[_vm._v(" "+_vm._s(cell.type === "string" ? _vm.i18n(cell, "value") : cell.value.toFixed(cell.decimal))+_vm._s(_vm.i18n(cell, "suffix"))+" ")]),_c('span',{staticClass:"d-block text-caption"},[_vm._v(_vm._s(_vm.i18n(cell, "hint")))])])}),0)}),0)]},proxy:true}],null,false,1370959621)})],1):_vm._e(),(_vm.field.type === 'fieldgroup')?_c('div',[_c('field-group',{attrs:{"form":_vm.form,"field":_vm.field}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }